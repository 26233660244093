import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { addFundsToWallet, getWalletBalance } from '../api/api.js';

const WalletScreen = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');

    useEffect(() => {
        if (isAuthenticated && user) {
            fetchWalletBalance();
            fetchTransactions();
        }
    }, [isAuthenticated, user]);

    const fetchWalletBalance = async () => {
        try {
            const balanceData = await getWalletBalance(user.email);
            setBalance(balanceData);
        } catch (error) {
            console.error("Error al obtener el saldo de la billetera", error);
        }
    };

    const fetchTransactions = async () => {
        // Implementar una función para obtener el historial de transacciones
    };

    const handleAddFunds = async () => {
        if (amount && !isNaN(amount)) {
            try {
                await addFundsToWallet(user.email, parseFloat(amount));
                await fetchWalletBalance();
                setAmount('');
                alert("Fondos agregados exitosamente");
            } catch (error) {
                alert("No se pudieron agregar los fondos. Por favor intenta nuevamente.");
            }
        } else {
            alert("Por favor ingresa una cantidad válida");
        }
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div>
            {isAuthenticated ? (
                <>
                    <h1>Billetera</h1>
                    <p>Saldo actual: ${balance}</p>
                    <div>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Monto a agregar"
                        />
                        <button onClick={handleAddFunds}>Agregar Fondos</button>
                    </div>
                </>
            ) : (
                <h2>Por favor, inicia sesión para acceder a tu billetera.</h2>
            )}
        </div>
    );
};

export default WalletScreen;