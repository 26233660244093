import React from 'react';
import ReactDOM from 'react-dom/client'; // Cambia aquí
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

// Obtén el contenedor root
const container = document.getElementById('root');

// Crea el root usando createRoot
const root = ReactDOM.createRoot(container);

// Renderiza la aplicación
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{redirectUri: `${window.location.origin}/callback`, audience: process.env.AUDIENCE}}
  >
    <App />
  </Auth0Provider>
);
