import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserBonos } from '../api/api.js';

const BonosScreen = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [bonos, setBonos] = useState([]);

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchUserBonos();
    }
  }, [isAuthenticated, user]);

  const fetchUserBonos = async () => {
    try {
      const userBonos = await getUserBonos(user.email);
      setBonos(userBonos);
    } catch (error) {
      console.error("Error al obtener los bonos del usuario", error);
    }
  };

  if (isLoading) return <div>Cargando...</div>;

  return (
    <div>
      <h1>Mis Bonos</h1>
      {isAuthenticated ? (
        <ul>
          {bonos.map((bono, index) => (
            <li key={index}>
              Partido: {bono.fixtureId}
              <br />
              Predicción: {bono.prediction}
              <br />
              Cantidad de bonos: {bono.amount}
              <br />
              Estado: {bono.status}
              {bono.status === 'won' && <p>Ganancia: ${bono.amount * bono.odds * 1000}</p>}
              <br />
              <br />
            </li>
          ))}
        </ul>
      ) : (
        <h2>Por favor, inicia sesión para ver tus bonos.</h2>
      )}
    </div>
  );
};

export default BonosScreen;