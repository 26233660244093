import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';

const Home = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    <div>
      <h1>Home</h1>
      {!isAuthenticated ? (
        <>
          <h2>Por favor, inicie sesión</h2>
        </>
      ) : (
        <>
          <h2>Bienvenido, {user.name}!</h2>
          <LogoutButton />
        </>
      )}
    </div>
  );
};

export default Home;

