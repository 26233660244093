import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { getFixtureDetails, getRecommendedFixtures, getHeartBeat } from '../api/api.js';

const RecommendationScreen = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [recommendations, setRecommendations] = useState([]);
  const [heartbeat, setHeartbeat] = useState(null);
  const [updatedFixture, setUpdatedFixture] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchUserrecommendations();
      fetchHeartbeat();
    }
  }, [isAuthenticated, user]);

  const fetchHeartbeat = async () => {
    try {
      const response = await getHeartBeat();
      console.log("response", response);
      setHeartbeat(response);
    } catch (error) {
      console.error("Error al obtener el heartbeat", error);
      setHeartbeat(false);
    }
  };

  const fetchUserrecommendations = async () => {
    try {
        console.log(`user.email: ${user.email}`);
        const userRecommendations = await getRecommendedFixtures(user.email);
        console.log("userRecommendations", userRecommendations);
        console.log(`userRecomendations.data: ${JSON.stringify(userRecommendations.recomendations)}`);
        // Verificar si userRecommendations.recomendations es un objeto y convertirlo a un arreglo
        setUpdatedFixture(userRecommendations.updateAt);
        const recommendationsArray = Object.entries(userRecommendations.recomendations).map(([key, value]) => ({
            id: key,
            recommendation: value,
        }));
        console.log(`userRecommendations.recomendations: ${JSON.stringify(recommendationsArray)}`);
        console.log(`userRecommendations.recomendations.length: ${recommendationsArray.length}`);
        setRecommendations(recommendationsArray);

        await fetchFixtures(recommendationsArray);
    } catch (error) {
        console.error("Error al obtener las recomendaciones del usuario", error);
    }
  };

  const fetchFixtures = async (recommendationsArray) => {
    try {
        const fixturesPromises = recommendationsArray.map(async (recommendation) => {
            const response = await getFixtureDetails(recommendation.recommendation);
            // Verificar si response tiene datos antes de acceder
        const fixtureData = response[0];
        if (!fixtureData) {
          throw new Error(`No se encontraron datos para la recomendación: ${recommendation.recommendation}`);
        }

        // Retorna el objeto con la estructura correcta
        return { id: recommendation.id, fixture: fixtureData };
      });

      const fixturesData = await Promise.all(fixturesPromises);
      console.log("fixturesData", fixturesData);
      setRecommendations(fixturesData); // Actualizar el estado con las fixtures completas
    } catch (error) {
      console.error("Error al obtener las fixtures", error);
    }
    };

  if (isLoading) return <div>Cargando...</div>;

  return (
    <div>
      <h1>Recomendaciones</h1>
      {isAuthenticated ? (
        <div>
          {heartbeat === null ? (
            <h2>Verificando estado del servicio...</h2>
          ) : heartbeat ? (
            <h2>El servicio de recomendaciones está disponible.</h2>
          ) : (
            <h2>El servicio de recomendaciones no está disponible.</h2>
          )}
          {recommendations.length === 0 && <h2>No hay recomendaciones disponibles, ya que no has ganado ningún partido.</h2>}
          <ul>
            {recommendations.map((recommendation) => (
              <li key={recommendation.id}>
                Partido: {recommendation.fixture ? recommendation.fixture.id  : 'No disponible'} {recommendation.fixture ? <Link to={`/fixtures/${recommendation.fixture.id}`}>Ver más detalles</Link> : ''}
                <br />
                {recommendation.fixture ? (
                  <>
                    (Local) {recommendation.fixture.homeTeamName} vs {recommendation.fixture.awayTeamName} (Visita)
                    <br />
                  </>
                ) : (
                  <span>Información del partido no disponible.</span>
                )}
                <br />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <h2>Por favor, inicia sesión para ver sus recomendaciones.</h2>
      )}
      <p>Última actualización: {updatedFixture ? updatedFixture : 'No disponible'}</p>
    </div>
  );
};

export default RecommendationScreen;