import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // URL del backend

// Función para agregar fondos a la billetera
export const addFundsToWallet = async (userEmail, amount) => {
    try {
        console.log(`add userEmail: ${userEmail}, amount: ${amount}`);
        const response = await fetch(`${API_BASE_URL}/wallet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
                value: amount
            }),
        });
        return response.data;
    } catch (error) {
        console.error("Error al agregar fondos", error);
        throw error;
    }
};

// función para obtener el saldo de la billetera del usuario
export const getWalletBalance = async (userEmail) => {
    try {
        console.log(`fetch ${API_BASE_URL}/user?email=${userEmail}`);
        const response = await axios.get(`${API_BASE_URL}/user?email=${userEmail}`);
        console.log(`get userEmail: ${userEmail}, data: ${response.data.wallet}`);
        return response.data.wallet;
    } catch (error) {
        console.error("Error al obtener el saldo de la billetera", error);
        throw error;
    }
};

export const getFixtureDetails = async (fixtureId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/fixtures/${fixtureId}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener detalles del partido", error);
        throw error;
    }
};

export const buyBono = async (userEmail, fixtureId, amount, cost, prediction) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/bonos`, {
            userEmail,
            fixtureId,
            amount,
            cost,
            prediction,
            paymentMethod: 'wallet', 
            wallet: true 
        });
        return response.data;
    } catch (error) {
        console.error("Error al comprar bono", error);
        throw error;
    }
};

export const getUserBonos = async (userEmail) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bonos?email=${userEmail}`);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los bonos del usuario", error);
      throw error;
    }
};

export const getRecommendedFixtures = async (userEmail) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/jobs?email=${userEmail}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener los partidos recomendados", error);
    }
};

export const getHeartBeat = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/heartbeat`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener heartbeat", error);
    }
};

export const createWebPayTransaction = async (userEmail, fixtureId, amount, cost, prediction) => {
    try {
        console.log('Iniciando transacción WebPay');
        const response = await axios.post(`${API_BASE_URL}/bonos`, {
            userEmail,
            fixtureId,
            amount,
            cost,
            prediction,
            paymentMethod: 'webpay',
            wallet: false
        });

        // Si recibimos la URL y token de WebPay
        if (response.data.url && response.data.token) {
            // Crear y enviar formulario programáticamente
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = response.data.url;

            const tokenInput = document.createElement('input');
            tokenInput.type = 'hidden';
            tokenInput.name = 'token_ws';
            tokenInput.value = response.data.token;

            form.appendChild(tokenInput);
            document.body.appendChild(form);
            form.submit();
        }

        return response.data;
    } catch (error) {
        console.error("Error creating WebPay transaction", error);
        throw error;
    }
};

// obtener estado de transacción WebPay
export const getWebPayTransactionStatus = async (token) => {
    try {
        console.log(`getWebPayTransactionStatus token: ${token}`);
        const response = await axios.post(`${API_BASE_URL}/webpay/confirm`, { token_ws: token });
        console.log('Respuesta de la transacción:', JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        console.error("Error getting WebPay transaction status", error);
        throw error;
    }
};