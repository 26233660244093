import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './Navbar.css'; 

const Navbar = () => {
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    return (
        <nav className="navbar">
            <div>
                <Link to="/" className="nav-link">
                    Home
                </Link>
                {isAuthenticated && (
                    <>
                        <Link to="/fixtures" className="nav-link">
                            Listado de Partidos
                        </Link>
                        <Link to="/wallet" className="nav-link">
                            Billetera
                        </Link>
                        <Link to="/bonos" className="nav-link">
                            Mis Bonos
                        </Link>
                        <Link to="/recommendations" className="nav-link">
                            Recomendaciones
                        </Link>
                    </>
                )}
            </div>
            <div>
                {!isAuthenticated ? (
                    <button
                        onClick={() => loginWithRedirect({ returnTo: `${window.location.origin}/callback` })}
                        className="login-button">
                        Iniciar Sesión
                    </button>
                ) : (
                    <>
                        <span className="welcome-message">Hola, {user.name}!</span>
                        <button
                            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                            className="logout-button">
                            Cerrar Sesión
                        </button>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
