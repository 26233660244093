import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/HomeScreen.js';
import Wallet from './views/WalletScreen.js';
import Navbar from './components/navbar/Navbar.js';
import Fixtures from './views/FixturesScreen.js';
import FixtureDetails from './views/FixturesDetail.js';
import BonosScreen from './views/BonosScreen.js';
import Callback from './views/Callback.js';
import Recommendations from './views/RecommendationScreen.js';
import WebPayReturn from './views/WebPayReturn';

function App() {
  return (
    <Router>
      <Navbar /> {}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wallet" element={<Wallet />} /> 
        <Route path="/fixtures" element={<Fixtures />} />
        <Route path="/fixtures/:id" element={<FixtureDetails />} />
        <Route path="/bonos" element={<BonosScreen />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/recommendations" element={<Recommendations />} />
        <Route path="/webpay-return" element={<WebPayReturn />} />
      </Routes>
    </Router>
  );
}

export default App;

