import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

function Fixtures() {
  const { logout } = useAuth0();
  
  const [fixtures, setFixtures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fixturesPerPage] = useState(25);
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [filterDate, setFilterDate] = useState('');
  const [filterTeam, setFilterTeam] = useState('');
  const [bonoRequest, setBonoRequest] = useState({ name: '', email: '', reason: '' }); // Estado para el bono
  const [requestStatus, setRequestStatus] = useState(''); // Estado para el estado de la solicitud

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesDataFetch = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fixtures`);
        if (fixturesDataFetch.ok) {
          const infoFixtures = await fixturesDataFetch.json();
          setFixtures(infoFixtures.fixture);
          setFilteredFixtures(infoFixtures.fixture);
        } else {
          console.log("No se pudo obtener la información de fixtures");
        }
      } catch (error) {
        console.error("Error fetching fixture details:", error);
      }
    };
    fetchFixtures();
  }, []);

  // Función para filtrar los fixtures por fecha y equipo
  const handleFilter = () => {
    let filtered = fixtures;

    if (filterDate) {
      filtered = filtered.filter(fixture => fixture.date === filterDate);
    }

    if (filterTeam) {
      filtered = filtered.filter(fixture => 
        fixture.homeTeamName.toLowerCase().includes(filterTeam.toLowerCase()) || 
        fixture.awayTeamName.toLowerCase().includes(filterTeam.toLowerCase())
      );
    }

    setFilteredFixtures(filtered);
    setCurrentPage(1); // Reiniciar la paginación al aplicar filtros
  };

  // Obtener los fixtures actuales para la página actual
  const indexOfLastFixture = currentPage * fixturesPerPage;
  const indexOfFirstFixture = indexOfLastFixture - fixturesPerPage;
  const currentFixtures = filteredFixtures.slice(indexOfFirstFixture, indexOfLastFixture);

  // Función para cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {/* Botón de cerrar sesión
      <div style={{ marginTop: '20px' }}>
        <button 
          onClick={() => logout({ returnTo: window.location.origin })} 
          style={{ padding: '10px', backgroundColor: 'red', color: 'white', border: 'none', cursor: 'pointer' }}>
          Cerrar Sesión y Volver a Home
        </button>
      </div> */}

      {/* Filtros por fecha y equipo */}
      <div style={{ marginTop: '20px' }}>
        <label>
          Filtrar por Fecha:
          <input 
            type="date" 
            value={filterDate} 
            onChange={(e) => setFilterDate(e.target.value)} 
            style={{ marginRight: '10px' }} 
          />
        </label>
        <label>
          Filtrar por Equipo:
          <input 
            type="text" 
            placeholder="Nombre del equipo" 
            value={filterTeam} 
            onChange={(e) => setFilterTeam(e.target.value)} 
            style={{ marginRight: '10px' }} 
          />
        </label>
        <button onClick={handleFilter} style={{ padding: '5px 10px' }}>
          Aplicar Filtros
        </button>
      </div>

      {/* Listado de fixtures */}
      <div className="App" style={{ marginTop: '20px' }}>
        <h1>Lista de Fixtures</h1>
        <ul>
          {currentFixtures.map(fixture => (
            <li key={fixture.id}>
              {fixture.date}: {fixture.homeTeamName} vs {fixture.awayTeamName}
              <Link to={`/fixtures/${fixture.id}`} style={{ marginLeft: '10px', color: 'blue', textDecoration: 'underline' }}>
                Ver más detalles
              </Link>
            </li>
          ))}
        </ul>

        {/* Paginación */}
        <div style={{ marginTop: '20px' }}>
          {Array.from({ length: Math.ceil(filteredFixtures.length / fixturesPerPage) }, (_, index) => (
            <button 
              key={index + 1} 
              onClick={() => paginate(index + 1)} 
              style={{ margin: '0 5px', padding: '5px 10px', backgroundColor: index + 1 === currentPage ? 'blue' : 'gray', color: 'white', border: 'none' }}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Fixtures;